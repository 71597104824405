import {Injectable} from '@angular/core'
import {map, switchMap, tap} from 'rxjs/operators'
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs'
import {HttpResponse} from "@angular/common/http";
import {
    ICreateObjectByModel,
    ICreateObjectByModelExtra,
    IObject,
    IObjectPathItem,
    IUpdateObject,
    ObjectsHttpService,
    Types,
    TypesUtils,
} from "@atl/lacerta-ui-common";
import {ILtaNodeData, LayoutType} from "@atl/modules/tree/interfaces/tree.interface";
import {flatten} from "lodash";
import {IDType} from "@ali-hm/angular-tree-component/lib/defs/api";

@Injectable()
export class ObjectsService {
    public readonly ROOT_NODE_ID = 1
    private activeObjectSubject = new BehaviorSubject<(IObject & { id?: number }) | null>(null)
    public activeObject$ = this.activeObjectSubject.asObservable()
    private rootObjectsSubject = new BehaviorSubject<IObject[]>([])
    public rootObjects$ = this.rootObjectsSubject.asObservable()
        .pipe(
            map(object => object.sort((a, b) => ObjectsService.sort(a, b)))
        );
    private objectsChildrenSubject = new BehaviorSubject<Map<number, IObject[]>>(new Map<number, IObject[]>());
    public objectsChildren$ = this.objectsChildrenSubject.asObservable()
    private objectsMapSubject = new BehaviorSubject<Map<number, IObject>>(new Map<number, IObject>());
    public objectsMap$ = this.objectsMapSubject.asObservable()
    private objectsPathMapSubject = new BehaviorSubject<Map<number, IObjectPathItem[]>>(new Map<number, IObjectPathItem[]>());
    public objectsPathMap$ = this.objectsPathMapSubject.asObservable()

    private bindLinkEventSubject = new Subject<IObject>();
    public bindLinkEvent$ = this.bindLinkEventSubject.asObservable()

    private unbindLinkEventSubject = new Subject<IObject>();
    public unbindLinkEvent$ = this.unbindLinkEventSubject.asObservable()

    constructor(private objectsHttp: ObjectsHttpService) {
    }

    public get objectsMap() {
        return this.objectsMapSubject.value
    }

    public get objectsPathMap() {
        return this.objectsPathMapSubject.value
    }

    static toTreeItem(obj: IObject, options: {
        expanded?: boolean,
        withAddItemBtn?: boolean,
        selectable?: boolean,
        disable?: boolean,
        children?: ILtaNodeData[],
        fromLink?: number,
        hasChildren?: boolean
    } = {
        expanded: false,
        withAddItemBtn: true,
        selectable: true,
        disable: false
    }): ILtaNodeData {
        let treeItem: ILtaNodeData = {
            item: obj,
            id: options.fromLink ? `link-${options.fromLink}-${obj.id}` : obj.id,
            hasChildren: options.hasChildren ? options.hasChildren : obj.model.id === Types.Link ? obj.link_object && TypesUtils.isDir(obj.link_object.type) : TypesUtils.isDir(obj.type),
            icon: TypesUtils.getIconByModelId(obj.model.id),
            name: obj.name,
            description: TypesUtils.getIconByModelId(obj.model.id) === 'composite' ? obj.model?.name : '',
            isExpanded: options.expanded ?? false,
            selectable: options.selectable ?? true,
            hasAddItemBtn: obj.model.id === Types.Link || options.fromLink ? false : options.withAddItemBtn ?? true,
            disabled: options.disable ?? false,
            children: options.children,
            theme: obj.model.id === Types.Link ? 'link' : null,
        };

        if (obj.driver_settings?.enabled || obj.imitation_settings?.enabled || obj.calculate_settings?.enabled || obj.event_settings?.enabled_off || obj.event_settings?.enabled_on) {
            treeItem.useLayout ? treeItem.useLayout.push(LayoutType.ObjectSettingsLayout) : treeItem.useLayout = [LayoutType.ObjectSettingsLayout];
        }
        if (obj.descr) {
            treeItem.useLayout ? treeItem.useLayout.push(LayoutType.ObjectDescriptionLayout) : treeItem.useLayout = [LayoutType.ObjectDescriptionLayout];
        }
        if (obj.type === Types.Link) {
            treeItem.useLayout ? treeItem.useLayout.push(LayoutType.ObjectLinkLayout) : treeItem.useLayout = [LayoutType.ObjectLinkLayout];
        }

        return treeItem;
    }

    static sort(a: IObject, b: IObject): number {
        const isBaseModel = (v: IObject) => v.model.id !== Types.Dir && !!Types[v.model.id]
        const isComposite = (v: IObject) => v.model.id !== Types.Dir && !Types[v.model.id]
        const isDir = (v: IObject) => TypesUtils.isDir(v.model.id)
        const sortString = (a: string, b: string) => a === b ? 0 : a > b ? 1 : -1

        if (isBaseModel(a) && isBaseModel(b)) {
            if (a.type === b.type) {
                return sortString(a.name, b.name);
            } else {
                return sortString(a.model.name, b.model.name)
            }
        }
        if (isDir(a) && isDir(b)) {
            return sortString(a.name, b.name)
        }

        if (isComposite(a) && isComposite(b)) {
            return sortString(a.name, b.name)
        }

        if (isBaseModel(a) && isDir(b)) {
            return -1
        } else if (isDir(a) && isBaseModel(b)) {
            return 1
        }

        if (isBaseModel(a) && isComposite(b)) {
            return -1
        } else if (isComposite(a) && isBaseModel(b)) {
            return 1
        }

        if (isDir(a) && isComposite(b)) {
            return -1
        } else if (isComposite(a) && isDir(b)) {
            return 1
        }
    }

    public bindLinkEvent(obj: IObject) {
        this.bindLinkEventSubject.next(obj)
    }

    public unbindLinkEvent(obj: IObject) {
        this.unbindLinkEventSubject.next(obj)
    }

    public bindLink(linkObj: IObject, origin_obj_id: number) {
        return this.objectsHttp.setLink(linkObj.id, origin_obj_id)
            .pipe(
                map(value => {
                    this.activeObjectSubject.next({...linkObj, link_object: value})
                    return {...linkObj, link_object: value}
                })
            )
    }

    public unbindLink(linkObj: IObject) {
        return this.objectsHttp.deleteLink(linkObj.id)
            .pipe(
                switchMap(() => this.getObjectById(linkObj.id, false)),
                tap(x => this.activeObjectSubject.next(x)),
            )
    }

    public setActiveObject(object: IObject) {
        this.activeObjectSubject.next(object)
    }

    public getObjectById(id: number, allowCache?: boolean): Observable<IObject> {
        if (allowCache) {
            const obj = this.objectsMapSubject.value.get(id)
            if (obj) return of(obj)
        }
        return this.objectsHttp.getObjectById(id)
            .pipe(
                tap(x => {
                    const map = this.objectsMapSubject.value
                    map.set(id, x)
                    this.objectsMapSubject.next(map)
                })
            )
    }

    public getObjectPath(id: number, allowCache?: boolean): Observable<IObjectPathItem[]> {
        if (allowCache) {
            const path = this.objectsPathMapSubject.value.get(id)
            if (path) return of(path)
        }
        return this.objectsHttp.getObjectPath(id)
            .pipe(
                tap(x => {
                    const map = this.objectsPathMapSubject.value
                    map.set(id, x)
                    this.objectsPathMapSubject.next(map)
                })
            )
    }

    public getRoot(): Observable<IObject[]> {
        return this.objectsHttp.getObjectChildrenById(this.ROOT_NODE_ID)
            .pipe(tap((objects) => {
                this.rootObjectsSubject.next(objects)
            }))
    }

    public getObjectChildren(id: number, force?: boolean): Observable<IObject[]> {
        const children = this.objectsChildrenSubject.value.get(id)
        if (children && !force) return of(children)
        return this.objectsHttp.getObjectChildrenById(id)
            .pipe(
                tap(x => {
                    const map = this.objectsChildrenSubject.value
                    map.set(id, x)
                    this.objectsChildrenSubject.next(map)
                })
            )
    }

    public addBlankObject(parent: IObject = null): void {
        this.activeObjectSubject.next({
            id: null,
            parent_id: parent?.id ?? 1,
            name: null,
            name_lng: null,
            descr: null,
            unit: null,
            modify_model: null,
            model: null,
            type: null,
            event_settings: null,
            calculate_settings: null,
            imitation_settings: null,
            tm_create: null,
            tm_update: null
        })
    }

    public setValue(id: number, value: string, ownerId?: number): Observable<string> {
        return this.objectsHttp.setValue(id, value, ownerId ? ownerId : id)
    }

    public searchForObject(string: string): Observable<{ toExpand: IDType[], all: IDType[] }> {
        return this.objectsHttp.searchForObject(string)
            .pipe(
                switchMap((objects) => {
                    if (objects.length === 0) return of({toExpand: [], all: []})
                    return forkJoin({
                        all: of(objects.map(o => o.id)),
                        toExpand: forkJoin(objects.map(o => this.getObjectPath(o.id, true)))
                            .pipe(
                                map(value => value.filter(v => v?.length !== 1)),
                                map(value => value.map(v => v.slice(0, -1))),
                                map(value => flatten(value).map(p => p.id)),
                                map(value => Array.from(new Set(value))),
                            )
                    })
                })
            ) as any
    }

    public createObject(object: ICreateObjectByModel, extra: ICreateObjectByModelExtra): Observable<IObject> {
        return this.objectsHttp.createObjectByModel(object, extra)
            .pipe(
                tap(obj => {
                    this.activeObjectSubject.next(obj)
                    const childrenMap = this.objectsChildrenSubject.value
                    const parentChildren = childrenMap.get(obj.parent_id)
                    if (parentChildren) {
                        childrenMap.set(obj.parent_id, [...parentChildren, obj])
                        this.objectsChildrenSubject.next(childrenMap)
                    }
                    if (obj.parent_id === this.ROOT_NODE_ID) {
                        this.rootObjectsSubject.next([...this.rootObjectsSubject.value, obj])
                    }
                })
            )
    }

    public updateObject(id: number, object: IUpdateObject): Observable<IObject> {
        return this.objectsHttp.updateObject(id, object)
            .pipe(
                tap(obj => {
                    const activeObject = this.activeObjectSubject.value
                    if (activeObject.type === Types.Link && activeObject.link_object?.id === id) {
                        this.activeObjectSubject.next({...activeObject, link_object: obj})
                    } else {
                        this.activeObjectSubject.next(obj)
                    }

                    const childrenMap = this.objectsChildrenSubject.value
                    const parentChildren = childrenMap.get(obj.parent_id)
                    if (parentChildren) {
                        childrenMap.set(obj.parent_id, parentChildren.map(v => {
                            if (v.id === obj.id) {
                                return obj
                            }
                            return v
                        }))
                        this.objectsChildrenSubject.next(childrenMap)
                    }
                    if (obj.parent_id === this.ROOT_NODE_ID) {
                        this.rootObjectsSubject.next(this.rootObjectsSubject.value.map(v => v.id === obj.id ? obj : v))
                    }
                })
            )
    }

    public restoreObject(obj: IObject): Observable<IObject> {
        return this.objectsHttp.restoreObject(obj.id)
            .pipe(
                tap(obj => {
                    this.activeObjectSubject.next(obj)
                    const childrenMap = this.objectsChildrenSubject.value
                    const parentChildren = childrenMap.get(obj.parent_id)
                    if (parentChildren) {
                        childrenMap.set(obj.parent_id, parentChildren.map(v => {
                            if (v.id === obj.id) {
                                return obj
                            }
                            return v
                        }))
                        this.objectsChildrenSubject.next(childrenMap)
                    }
                    if (obj.parent_id === this.ROOT_NODE_ID) {
                        this.rootObjectsSubject.next(this.rootObjectsSubject.value.map(v => v.id === obj.id ? obj : v))
                    }
                })
            )
    }

    public deleteObject(object: IObject, flags: {
        forceMeta?: boolean,
        forceData?: boolean
    } = {}): Observable<HttpResponse<null>> {
        return this.objectsHttp.deleteObject(object.id, flags)
            .pipe(
                tap(() => {
                    this.activeObjectSubject.next(null)
                    const childrenMap = this.objectsChildrenSubject.value
                    const parentChildren = childrenMap.get(object.parent_id)
                    if (parentChildren) {
                        childrenMap.set(object.parent_id, parentChildren.filter(v => v.id !== object.id))
                        this.objectsChildrenSubject.next(childrenMap)
                    }
                    if (object.parent_id === this.ROOT_NODE_ID) {
                        this.rootObjectsSubject.next(this.rootObjectsSubject.value.filter(v => v.id !== object.id))
                    }
                })
            )
    }
}
